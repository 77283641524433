<template>
  <div class="max-w-screen-2xl mx-auto px-4 py-2 lg:px-4">
    <div class="flex items-center justify-between">
      <div class="flex flex-row">
        <sort-label
          name="name"
          :placeholder="$t('name')"
          classes="flex pr-1 w-44 md:w-80"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
        <sort-label
          name="users"
          :placeholder="$t('users')"
          classes="hidden px-1 sm:flex w-36"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
      </div>
      <div v-if="isAdmin" class="flex flex-row">
        <sort-label
          name="status"
          :placeholder="$t('status')"
          classes="flex justify-end sm:flex w-20"
          :sortData="sort"
          @sort="onSort"
        ></sort-label>
        <div class="flex justify-end sm:flex w-20">
          <p class="font-semibold text-sm">{{ $t("action") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SortLabel from "@/components/SortLabel";
import { mapGetters, mapMutations } from "vuex";

export default {
  components: { SortLabel },
  computed: {
    ...mapGetters("sub_subgroups", ["sort"]),
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return this.currentUser && this.currentUser?.role?.group?.admin;
    },
  },
  methods: {
    ...mapMutations("sub_subgroups", ["sortSubSubGroup", "toggleSort"]),
    onSort(by) {
      this.toggleSort();
      this.sortSubSubGroup(by);
    },
  },
};
</script>
