<template>
  <modal :show="showModal" @onClose="closeModal">
    <template #header>{{ $t("add-sub-sub-group") }}</template>
    <template #body
      ><Sub_SubGroupForm
        @onClose="closeModal"
        :subgroupId="$route.params.id"
        :sub_subgroup="selected_sub_subGroup"
    /></template>
  </modal>

  <Modal :show="showUserModal" @onClose="closeUserModal">
    <template #header>{{ $t("assign-user") }}</template>
    <template #body
      ><AssignSub_SubUserForm @onClose="closeUserModal"
    /></template>
  </Modal>

  <!-- show only if loading from server not from cache -->
  <Loader v-if="loading && list.length === 0" />
  <div v-else>
    <section class="max-w-screen-2xl mx-auto pt-6 px-2 sm:px-6 lg:px-8">
      <div class="flex flex-row items-center justify-start">
        <div class="flex items-center">
          <div class="mr-2">
            <img src="/icon-top-users.svg" class="h-4 w-4" />
          </div>
          <div>
            <h1 class="font-bold text-WADARKBLUE-500 text-base uppercase">
              {{ $t("sub-sub-group") }}
            </h1>
          </div>
        </div>
      </div>
    </section>
    <section class="max-w-screen-2xl mx-auto px-2 py-6 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between">
        <div class="flex items-center flex-wrap">
          <button
            v-if="isAdmin"
            @click="openModal"
            class="bg-WAORANGE-500 hover:bg-WAORANGE-400 mr-2 px-4 py-2 rounded-lg text-white text-xs uppercase mb-2 sm:mb-0"
          >
            + {{ $t("sub-sub-group") }}
          </button>
          <button
            v-if="isAdmin"
            @click="openUserModal"
            class="border border-WAORANGE-500 hover:bg-white mr-2 px-4 py-2 rounded-lg text-xs uppercase mb-2 sm:mb-0"
          >
            {{ $t("assign-user") }}
          </button>
        </div>
        <sub-sub-group-filter />
      </div>
    </section>
    <section class="lg:px-8 max-w-screen-2xl mx-auto px-2 sm:px-6">
      <sub-sub-group-table-row></sub-sub-group-table-row>
      <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
        <span>* {{ error }}</span>
      </div>
      <div v-if="filteredSubSubGroups.length > 0">
        <Group
          v-for="sub_subgroup in filteredSubSubGroups"
          :key="sub_subgroup._id"
          :group="sub_subgroup"
          :isSubSub="true"
          @edit="editSubGroup(sub_subgroup)"
        />
      </div>
      <div v-else>
        <h1 class="text-sm text-center text-WADARKBLUE-500 my-10">
          {{ $t("no-data") }}
        </h1>
      </div>
    </section>
  </div>
</template>

<script>
import Group from "@/components/groups/Group.vue";
import Sub_SubGroupForm from "@/components/groups/Sub_SubGroupForm.vue";
import AssignSub_SubUserForm from "@/components/groups/AssignSub_SubUserForm.vue";
import SubSubGroupFilter from "../components/groups/Sub_SubGroupFilter.vue";
import SubSubGroupTableRow from "@/components/groups/SubSubGroupTableRow.vue";
import Loader from "@/components/Loader.vue";
import Modal from "@/components/Modal.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Group,
    Loader,
    Sub_SubGroupForm,
    Modal,
    AssignSub_SubUserForm,
    SubSubGroupFilter,
    SubSubGroupTableRow,
  },
  computed: {
    ...mapGetters("sub_subgroups", [
      "loading",
      "error",
      "list",
      "filteredSubSubGroups",
    ]),
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return (
        (this.currentUser && this.currentUser?.role?.group?.admin) ||
        this.currentUser?.isSuper
      );
    },
  },
  async created() {
    this.subgroupId = this.$route.params?.id;
    await this.getSubSubGroups(this.subgroupId);
  },
  data() {
    return {
      showModal: false,
      showUserModal: false,
      subgroupId: "",
      selected_sub_subGroup: null,
    };
  },

  methods: {
    ...mapActions("sub_subgroups", ["getSubSubGroups"]),
    openModal() {
      this.showModal = true;
      this.selected_sub_subGroup = null;
    },
    closeModal() {
      this.showModal = false;
      this.selected_sub_subGroup = null;
    },
    openUserModal() {
      this.showUserModal = true;
    },
    closeUserModal() {
      this.showUserModal = false;
    },
    editSubGroup(sub_subgroup) {
      this.selected_sub_subGroup = sub_subgroup;
      this.showModal = true;
    },
  },
  watch: {
    subgroupId(newVal) {
      this.subgroupId = newVal;
    },
  },
};
</script>
