<template>
  <div class="relative">
    <button @click="toggleFilter" class="flex items-center">
      <img src="/icon-filters.svg" alt="filter" />
    </button>
    <Transition name="slide-down-fade">
      <div class="absolute right-0 top-10 z-10 filter_class" v-if="showFilter">
        <div
          class="
            flex-col
            items-center
            justify-between
            gap-2
            bg-white
            p-3
            rounded
            shadow-md
          "
        >
          <select-input
            :data="getInputData('name')"
            :placeholder="$t('name')"
            :filterMode="true"
            v-model="name"
            size="small"
            width="full"
          />
          <select-input
            :data="getArrayInputData('users')"
            :placeholder="$t('users')"
            :filterMode="true"
            v-model="user"
            size="small"
            width="full"
          />
          <select-input
            v-if="isAdmin"
            :data="statusData"
            :placeholder="$t('status')"
            :filterMode="true"
            v-model="status"
            size="small"
            width="full"
          />
        </div></div
    ></Transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  computed: {
    ...mapGetters("sub_subgroups", ["list"]),
    ...mapGetters(["currentUser"]),
    isAdmin() {
      return this.currentUser && this.currentUser?.role?.group?.admin;
    },
  },
  components: { SelectInput },

  data() {
    return {
      name: "",
      status: "",
      user: "",
      filterData: { name: "", user: "", status: "" },
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
      showFilter: false,
    };
  },
  methods: {
    ...mapActions("sub_subgroups", ["filterSubSubGroup"]),
    getInputData(name) {
      return this.list.map((subsub) => ({
        id: subsub.id,
        value: subsub[name],
      }));
    },
    getArrayInputData(name) {
      let dummyUser = [];

      const allUsers = this.list.map((subsub) => subsub[name]);
      allUsers.forEach((users) => {
        users.forEach((user) => {
          const usrs = dummyUser;
          const usr = this.parseProxy(user);
          const idx = usrs.findIndex((u) => u._id === usr._id);

          if (idx === -1) dummyUser.push(this.parseProxy(user));
        });
      });

      return [...dummyUser.map((u) => ({ id: 1, value: u.name }))];
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    close(e) {
      if (!this.$el.contains(e.target)) {
        this.showFilter = false;
      }
    },

    parseProxy(proxy) {
      return JSON.parse(JSON.stringify(proxy));
    },
  },
  watch: {
    name(newVal) {
      this.filterData = { ...this.filterData, name: newVal };
      this.filterSubSubGroup(this.filterData);
    },

    user(newVal) {
      this.filterData = { ...this.filterData, user: newVal };
      this.filterSubSubGroup(this.filterData);
    },

    status(newVal) {
      this.filterData = { ...this.filterData, status: newVal };
      this.filterSubSubGroup(this.filterData);
    },
  },

  mounted() {
    document.addEventListener("click", this.close);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.close);
  },
};
</script>
