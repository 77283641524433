<template>
  <form @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>
    <div class="flex items-center flex-wrap gap-3 mb-4">
      <input
        class="rounded w-full"
        :placeholder="$t('sub-sub-group-name')"
        type="text"
        v-model="name"
      />
    </div>
    <div class="mb-4" v-if="sub_subgroup">
      <select-input
        :placeholder="$t('status')"
        :data="statusData"
        v-model="status"
        size="full"
      />
    </div>
    <div class="flex relative justify-end pt-2 gap-x-3">
      <button
        :disabled="loading"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          this.sub_subgroup
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? "Adding Sub-SubGroup "
            : $t("add-sub-sub-group")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { mapMutations } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";

export default {
  components: { SelectInput },
  data() {
    return {
      loading: false,
      error: null,
      name: this.sub_subgroup ? this.sub_subgroup.name : "",
      status: this.sub_subgroup ? this.sub_subgroup.status : "",
      statusData: [
        { id: 1, value: "Active" },
        { id: 2, value: "Disabled" },
      ],
    };
  },
  emits: ["onClose"],
  methods: {
    ...mapMutations("sub_subgroups", [
      "addSubSubGroup",
      "removeSubSubGroup",
      "updateSubSubGroup",
    ]),
    async onSubmit() {
      if (this.error) this.error = null;
      if (!this.name) return (this.error = "Please fill in all fields");

      try {
        this.loading = true;

        if (this.sub_subgroup) {
          const { data } = await httpClient.put(
            `/admin/sub-subgroups/${this.sub_subgroup._id}`,
            {
              name: this.name,
              status: this.status,
            }
          );

          this.updateSubSubGroup(data);
        } else {
          const { data } = await httpClient.post(
            `/admin/sub-subgroups/subgroup/${this.subgroupId}`,
            {
              name: this.name,
            }
          );

          this.addSubSubGroup(data);
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    async deleteSubGroup() {
      if (this.error) this.error = null;

      try {
        this.deleting = true;
        await httpClient.delete(
          `/admin/sub-subgroups/${this.sub_subgroup._id}`
        );

        this.deleting = false;
        this.removeSubSubGroup(this.sub_subgroup._id);
        this.$emit("onClose");
      } catch (err) {
        this.deleting = false;
        this.error =
          err.response && err.response.data.error
            ? err.response.data.error
            : err.error || err.message;
      }
    },

    confirmed() {
      this.closeConfirmModal();
      this.deleteSubGroup();
    },

    openConfirmModal() {
      this.showConfirm = true;
    },

    closeConfirmModal() {
      this.showConfirm = false;
    },
  },
  props: ["subgroupId", "sub_subgroup"],
};
</script>

<style scoped>
input {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

form {
  width: 80vw;
}

@media screen and (min-width: 640px) {
  form {
    width: 400px;
  }
}
</style>
